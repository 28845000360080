import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  wallet: null,
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setWallet: (state, action) => {
      state.wallet = action.payload;
    },
  },
});

export const { setWallet } = walletSlice.actions;
export const selectWallet = (state) => state.wallet.wallet;

export default walletSlice.reducer;
