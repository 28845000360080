import {createSlice} from '@reduxjs/toolkit'

const initialState={
    user:null,
    isAuthenticated:false,
}

// when we create a slice we need 3 parametrs   name, state, function 

const userSlice=createSlice({
    name:'user',
    initialState,
    reducers:{
        setUser:(state, action)=>{
            state.user=action.payload;
            state.isAuthenticated=true;
        },
        logout:(state)=>{
            state.user=null;
            state.isAuthenticated=false;
        }
    }
});

//these are my user functions 

export const {setUser, logout}=userSlice.actions;


//these fucntions are used to get the details from the state
export const selectUser= (state)=>state.user.user;
export const selectIsAuthenticated =(state)=>state.user.isAuthenticated;

export default userSlice.reducer;