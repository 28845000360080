import React from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import HomePage from "./pages/Home";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import Playground from "./pages/Playground";
import Layout from "./components/Layout";

import './index.css';
import Wallet from "./pages/Wallet";

const App = () => {

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/playground"
          element={
            <ProtectedRoute>
              <Playground />
            </ProtectedRoute>
          }
        />
        <Route
        path="/wallet"
        element={
          <ProtectedRoute>
          <Wallet/>
          </ProtectedRoute>
        }
        />
      </Routes>
    </Layout>
  );
};

export default App;
