import React from 'react';
import { Link } from 'react-router-dom';
import { IoIosArrowForward } from "react-icons/io";
import { CiWallet,CiTrophy,CiCircleQuestion, CiHome } from "react-icons/ci";
import { MdOutlineHistory, MdOutlinePersonAdd } from "react-icons/md";
import { IoDocumentOutline } from "react-icons/io5";
import { IoIosNotificationsOutline } from "react-icons/io";
import image from '../images/images.jpeg'
const Sidebar = ({ open, setopen }) => {
  const closeSidebar = () => {
    setopen(false);
  };

  return (
    <>
      {/* Overlay */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-85 transition-opacity duration-300 ease-in-out ${
          open ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        style={{ zIndex: 1000 }}
        onClick={closeSidebar}
        
      />

      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 h-full w-72 bg-white shadow-lg transition-transform duration-300 ease-in-out ${
          open ? "translate-x-0" : "-translate-x-full"
        }`}
        style={{ zIndex: 1000 }}
        onClick={closeSidebar}
      >
        <ul>
          <li>
            <div className="m-3 flex  p-2">
              <Link
                to="/"
                className="flex justify-between items-center w-full relative"
              >
                <div className="flex items-center gap-4">
                  <CiHome size="2em" />
     
                  <span className="hover:underline">Home</span>
                </div>
                <IoIosArrowForward
                  color="black"
                  size="1.5em"
                  className="absolute right-0"
                />
              </Link>
            </div>

            <hr className="bg-red-800 shadow-md shadow-black h-0.5" />
          </li>

          <li>
            <div className="m-3 flex  p-2">
              <Link
                to="/profile"
                className="flex justify-between items-center w-full relative"
              >
                <div className="flex items-center gap-4">
                  <img src={image} className='h-8 w-8 rounded-lg ' alt="" />
     
                  <span className="hover:underline">My Profile</span>
                </div>
                <IoIosArrowForward
                  color="black"
                  size="1.5em"
                  className="absolute right-0"
                />
              </Link>
            </div>

            <hr className="bg-red-800 shadow-md shadow-black h-0.5" />
          </li>

          <li>
            <div className="m-3 flex  p-2">
              <Link
                to="/profile"
                className="flex justify-between items-center w-full relative"
              >
                <div className="flex items-center gap-4">
                  <CiTrophy size="2em" color='green' />
                  <span className="hover:underline">Game History</span>
                </div>
                <IoIosArrowForward
                  color="black"
                  size="1.5em"
                  className="absolute right-0"
                />
              </Link>
            </div>

            <hr className="bg-red-800 shadow-md shadow-black h-0.5" />
          </li>

          <li>
            <div className="m-3 flex  p-2">
              <Link
                to="/wallet"
                className="flex justify-between items-center w-full relative"
              >
                <div className="flex items-center gap-4">
                  <CiWallet  size="2em" color='green' />
                  <span className="hover:underline">Wallet</span>
                </div>
                <IoIosArrowForward
                  color="black"
                  size="1.5em"
                  className="absolute right-0"
                />
              </Link>
            </div>

            <hr className="bg-red-800 shadow-md shadow-black h-0.5" />
          </li>
          <li>
            <div className="m-3 flex  p-2">
              <Link
                to="/profile"
                className="flex justify-between items-center w-full relative"
              >
                <div className="flex items-center gap-4">
                  <MdOutlineHistory  size="2em" color='green' />
                  <span className="hover:underline">Transactions</span>
                </div>
                <IoIosArrowForward
                  color="black"
                  size="1.5em"
                  className="absolute right-0"
                />
              </Link>
            </div>

            <hr className="bg-red-800 shadow-md shadow-black h-0.5" />
          </li>
         
          <li>
            <div className="m-3 flex  p-2">
              <Link
                to="/profile"
                className="flex justify-between items-center w-full relative"
              >
                <div className="flex items-center gap-4">
                  <IoDocumentOutline  size="2em" color='green' />
                  <span className="hover:underline">TDS Certificate</span>
                </div>
                <IoIosArrowForward
                  color="black"
                  size="1.5em"
                  className="absolute right-0"
                />
              </Link>
            </div>

            <hr className="bg-red-800 shadow-md shadow-black h-0.5" />
          </li>

          <li>
            <div className="m-3 flex  p-2">
              <Link
                to="/profile"
                className="flex justify-between items-center w-full relative"
              >
                <div className="flex items-center gap-4">
                  <MdOutlinePersonAdd  size="2em" color='green' />
                  <span className="hover:underline">Refer & Earn </span>
                </div>
                <IoIosArrowForward
                  color="black"
                  size="1.5em"
                  className="absolute right-0"
                />
              </Link>
            </div>

            <hr className="bg-red-800 shadow-md shadow-black h-0.5" />
          </li>

          <li>
            <div className="m-3 flex  p-2">
              <Link
                to="/profile"
                className="flex justify-between items-center w-full relative"
              >
                <div className="flex items-center gap-4">
                  <IoIosNotificationsOutline  size="2em" color='green' />
                  <span className="hover:underline">Notification </span>
                </div>
                <IoIosArrowForward
                  color="black"
                  size="1.5em"
                  className="absolute right-0"
                />
              </Link>
            </div>

            <hr className="bg-red-800 shadow-md shadow-black h-0.5" />
          </li>

          <li>
            <div className="m-3 flex  p-2">
              <Link
                to="/profile"
                className="flex justify-between items-center w-full relative"
              >
                <div className="flex items-center gap-4">
                  <CiCircleQuestion  size="2em" color='green' />
                  <span className="hover:underline">Support </span>
                </div>
                <IoIosArrowForward
                  color="black"
                  size="1.5em"
                  className="absolute right-0"
                />
              </Link>
            </div>

            <hr className="bg-red-800 shadow-md shadow-black h-0.5" />
          </li>

          

          
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
