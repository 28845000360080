// data.js
import { LuSwords } from "react-icons/lu";
import { TbLivePhoto } from "react-icons/tb";

// data.js
export const gamesData = [
    {
      id: 1,
      title: "Yodha Ludo",
      imgSrc: "https://cdn.pixabay.com/photo/2015/07/10/11/11/luck-839036_640.jpg",
      status: "Live",
      link: "/playground", // Link to the specific game page
    },
    // {
    //   id: 2,
    //   title: "Yodha Ludo",
    //   imgSrc: "https://cdn.pixabay.com/photo/2024/03/08/11/09/tokens-8620520_640.png",
    //   status: "Live",
    //   link: "/yodha-ludo-2", // Another specific game page
    // },
    // {
    //   id: 3,
    //   title: "Yodha Ludo",
    //   imgSrc: "https://cdn.pixabay.com/photo/2021/11/10/18/09/casino-6784518_640.jpg",
    //   status: "Live",
    //   link: "/yodha-ludo-3", // Another specific game page
    // },
    // {
    //   id: 4,
    //   title: "Yodha Ludo",
    //   imgSrc: "https://cdn.pixabay.com/photo/2016/03/17/23/09/roulette-1264078_640.jpg",
    //   status: "Main",
    //   link: "/yodha-ludo-43", // Another specific game page
    // },
  ];


export const iconMap = {
  battle: <LuSwords color="red" />,
  live: <TbLivePhoto />,
};
