import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { useDispatch } from "react-redux";  // To update Redux state
import { setUser, logout } from "../redux/userSlice";  // Redux actions for user state
import { setWallet } from "../redux/walletSlice";  // Redux actions for wallet state
import CircularProgress from '@mui/material/CircularProgress';

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otpCode, setOtp] = useState('');
  const [loaderState, setLoaderState] = useState(false);
  const [confirmationSent, setConfirmationSent] = useState(false);

  const apiUrl = process.env.REACT_APP_API_LINK;
  const navigate = useNavigate();
  const dispatch = useDispatch();  // Redux dispatch for updating store

  const onSignInSubmit = async (e) => {
    e.preventDefault();
    setLoaderState(true);
    try {
      await axios.post(`${apiUrl}auth/sendotp`, { phoneNumber: `+91 ${phoneNumber}` }, { withCredentials: true });
      setConfirmationSent(true);  // OTP is sent, now show OTP input
    } catch (error) {
      console.error('Error sending OTP:', error);
    }
    setLoaderState(false);
  };

  const onSubmitOtp = async (e) => {
    e.preventDefault();
    setLoaderState(true);
    try {
      // Verify OTP
      await axios.post(`${apiUrl}auth/verifyotp`, { phoneNumber: `+91 ${phoneNumber}`, otpCode }, { withCredentials: true });

      // Fetch user data after successful OTP verification
      const userResponse = await axios.get(`${apiUrl}user/getuserinfo`, { withCredentials: true });
      const userData = userResponse.data.user;
      dispatch(setUser(userData));  // Update Redux with user data

      // Fetch wallet details
      const walletResponse = await axios.get(`${apiUrl}wallet/getwalletdetails`, { withCredentials: true });
      const walletData = walletResponse.data.wallet;
      dispatch(setWallet(walletData));  // Update Redux with wallet data

      // After user is authenticated and state is set, navigate to home
      navigate('/');
    } catch (error) {
      console.error('Error verifying OTP:', error);
    }
    setLoaderState(false);
  };

  return (
    <div className="relative bg-black h-screen flex flex-col items-center justify-center overflow-hidden">
      <div id="recaptcha-container"></div> {/* Container for reCAPTCHA */}
      <div>
        <video
          className="absolute inset-0 object-cover h-2/3"
          src="https://media.istockphoto.com/id/164185925/video/pair-of-aces-falling-on-a-table.mp4?s=mp4-640x640-is&k=20&c=eKErU1ad6pET2lsKyKUL2o2Li4uSrEzwCu6EY-6xwdQ="
          autoPlay
          loop
          muted
        />
      </div>
      <div className="absolute top-4 left-16 transform -translate-x-1/2 z-20">
        <Link to='/'>
          <button className="px-6 py-2 border-white border-2 text-black rounded hover:text-black">
            <IoIosArrowBack style={{ color: "white" }} />
          </button>
        </Link>
      </div>
      <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black to-black z-10"></div>
      <div className="relative z-20 text-center w-full">
        <h2 className="font-bold text-2xl text-white mb-4 font-sans pb-5">
          Sign in or Sign Up
        </h2>
        <div className="bg-white rounded-xl ml-3 mr-3 h-16 flex place-content-center items-center">
          {!confirmationSent ? (
            <div className="border-gray-400 border-2 rounded-sm flex">
              <p className="bg-gray-300 p-2">+91</p>
              <input
                className="pl-5 bg-white"
                placeholder="Mobile number"
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
          ) : (
            <div className="border-gray-400 border-2 rounded-sm flex">
              <p className="bg-gray-300 p-2">OTP</p>
              <input
                className="pl-5 bg-white"
                placeholder="Enter Your OTP here"
                type="tel"
                value={otpCode}
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
          )}
        </div>
        <div className="z-20 text-white bg-green-500 font-sans text-xl font-semibold p-3 rounded-xl m-5">
          {!confirmationSent ? (
            <button type="submit" onClick={onSignInSubmit}>
              {!loaderState ? <p>Send OTP</p> : <CircularProgress color="inherit" />}
            </button>
          ) : (
            <button type="submit" onClick={onSubmitOtp}>
              {!loaderState ? <p>Verify OTP</p> : <CircularProgress color="inherit" />}
            </button>
          )}
        </div>
      </div>
      <div className="z-20 text-white text-sm flex text-center absolute bottom-3 font-sans m-5">
        <span>By proceeding, you agree to our Terms of Use, Privacy Policy and that you are 18 years or older. You are not playing from Assam, Odisha, Nagaland, Sikkim, Meghalaya, Andhra Pradesh, or Telangana.</span>
      </div>
    </div>
  );
};

export default Login;
